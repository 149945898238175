import React from 'react'

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property value called `fill`. `fill` is useful
 * when you want to change your logo depending on the theme you are on.
 */
export default function Logo({fill}) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 201.59 24.44"
      height="24px"
    >
      <path
        transform="translate(-1.23 -0.56)"
        fill={fill}
        d="M7.06,16.59,7.25,3.08S7,1.2,6.1,1.2h5.14c-.93,0-1.15,1.88-1.15,1.88l-.2,13.43c-.48,7.18-5.76,7.33-7.06,7.29C3.72,23.62,6.6,22,7.06,16.59Z"
      />
      <path
        transform="translate(-1.23 -0.56)"
        fill={fill}
        d="M28.62,15.19c0,4.76-3.11,8.61-7.54,8.61S13.55,20,13.55,15.19s3.1-8.6,7.53-8.6S28.62,10.44,28.62,15.19Zm-2.6,0c0-4-2.21-8-4.94-8s-4.93,4-4.93,8,2.21,7.91,4.93,7.91S26,19.21,26,15.19Z"
      />
      <path
        transform="translate(-1.23 -0.56)"
        fill={fill}
        d="M42.12,12.08c.22-6.48-5.86-6-7.63-1.86v11c0,.93.65,2.3,1.15,2.3H31c.5,0,1.13-1.32,1.13-2.27V3.2C32,2.61,31.66,1.35,31,1.35h3.53V8.91c2.08-3.9,10-3,10,3.11,0,1.88,0,5.39,0,8.05v1.15c0,.93.65,2.3,1.14,2.3H41c.5,0,1.06-1.05,1.12-2.27Z"
      />
      <path
        transform="translate(-1.23 -0.56)"
        fill={fill}
        d="M62.71,23.52H58c.85,0,1.1-1.3,1.11-1.77,0-2,0-9.3,0-9.3,0-6.34-5.85-6.17-7.59-1.43V21.75s0,1.77,1.13,1.77H48c1,0,1.12-1.34,1.13-1.69V9.11c0-.69-.43-2.18-1.13-2.18h3.54V9.29c2.08-3.9,10-3,10,3.11,0,2,0,9.06,0,9.35C61.59,22.27,61.63,23.52,62.71,23.52Z"
      />
      <path
        fill="#b3b3b3"
        transform="translate(-1.23 -0.56)"
        d="M77.14,22.61H85.7c1.7,0,2.26-2.71,2.71-3.12v3.85h-15c1-.08,1.11-1.55,1.12-1.94V2.76c-.1-.53-.42-1.67-1.12-1.67h4.82c-.69,0-1,1.14-1.12,1.67V22.44S77.14,22.54,77.14,22.61Z"
      />
      <path
        fill="#b3b3b3"
        transform="translate(-1.23 -0.56)"
        d="M104.12,23.52h-3.91v-1c-3.69,2.49-9.85,1.14-9.46-4,.47-6.38,9.36-2.41,9.46-7.62.05-2.4-1.4-4-3.24-3.95-3.61.13-3.52,2.82-2.63,3.29a1.4,1.4,0,0,1-.44,2.65c-1.94.36-2.61-1-2.71-2.27.32-5.3,11.66-6,11.66.45V21.35S103.09,23.52,104.12,23.52Zm-3.91-1.83V13.61A4.51,4.51,0,0,1,97.39,15C90.37,16.33,93.79,26.73,100.21,21.69Z"
      />
      <path
        fill="#b3b3b3"
        transform="translate(-1.23 -0.56)"
        d="M120.5,19.31c-.5,2.67-2.39,4.62-6.11,4.62-10.29,0-10.74-17.57-.12-17.86,5.76-.16,6.64,4.83,5.47,6.33-1.44,1.84-4,.12-2.79-1.52.43-.6.72-.47.71-1.28,0-1.67-1.31-2.83-3.33-2.83-7.19,0-6.73,16.23.16,16.37a4.76,4.76,0,0,0,5.14-4Z"
      />
      <path
        fill="#b3b3b3"
        transform="translate(-1.23 -0.56)"
        d="M138,15.19c0,4.76-3.11,8.61-7.54,8.61s-7.53-3.85-7.53-8.61,3.11-8.6,7.53-8.6S138,10.44,138,15.19Zm-2.6,0c0-4-2.21-8-4.94-8s-4.93,4-4.93,8,2.21,7.91,4.93,7.91S135.35,19.21,135.35,15.19Z"
      />
      <path
        fill="#b3b3b3"
        transform="translate(-1.23 -0.56)"
        d="M160.56,21.39c.05-2.33.05-2.33,0-8.82,0-5.37-4.39-7.45-7.3-.82,0,0,0,7,.06,9.64,0,.6.21,2.13,1.08,2.13h-4.53c.87,0,1.07-1.53,1.08-2.13.05-2.26,0-8.82,0-8.82,0-6.44-5.61-6.08-7.26-1.85V21.39c0,.6.22,2.13,1.09,2.13H140.3c.87,0,1.07-1.53,1.08-2.13.06-3.06,0-12.6,0-12.6C141.27,8.21,141,7,140.3,7h3.39V9.41c1.86-3.45,7.54-3.22,9.17.74.11-.19.35-.57.43-.74,2.24-4.07,9.62-3,9.62,3.1,0,2.2,0,6.25,0,8.88,0,.6.22,2.13,1.09,2.13h-4.53C160.35,23.52,160.55,22,160.56,21.39Z"
      />
      <path
        fill="#b3b3b3"
        transform="translate(-1.23 -0.56)"
        d="M181.37,15c0,4.76-2.83,8.62-6.88,8.62a6.11,6.11,0,0,1-4.59-2.09v2h-3.52c.9,0,1.11-2.19,1.11-2.19l0-18.12c0-.88-.43-1.89-1.12-1.89H171c-.69,0-1.07,1-1.11,1.89V8.65a6.22,6.22,0,0,1,4.59-2C178.54,6.68,181.37,10.27,181.37,15Zm-2.82-.15c0-4-1.57-7.49-4.06-7.49-2.08,0-4,1.15-4.49,4.28-.11.62,0,5.06,0,5.72,0,3.23,2.16,5.53,4.65,5.53S178.55,18.9,178.55,14.88Z"
      />
      <path
        fill="#b3b3b3"
        transform="translate(-1.23 -0.56)"
        d="M196.21,14.6h-9.68c0,.15,0,.31,0,.47,0,4,1.75,7.91,5,7.91s5-2.44,4.86-3.95c-.07-.79-.58-.64-1-1.3-1.14-1.73,1.8-3.29,2.67-1.22s-.72,7.17-6.79,7.17c-4.44,0-7.56-3.86-7.56-8.61s3.12-8.61,7.56-8.61,7.34,3.38,7.34,8.14Zm-9.61-.95h9.56c-.31-3.61-2.38-6.57-4.89-6.57S187,10.16,186.6,13.65Z"
      />
    </svg>
  )
}
